import React from "react";

function Portfolio() {
  return (
  
  
  <div>


<p className="font-semibold py-6 px-2">I am always interested to hear from new people so feel free to get in touch! <br/>Drop me an email, message me on LinkedIn or check out more of my stuff on my github :)</p>
    
  </div>


  )
}

export default Portfolio;
